.whydivo {
  width: 100%;
  overflow-x: hidden;
  .banner {
    img {
      width: 100vw;
      height: 100vh;

      object-fit: cover;
    }
    .banner-text {
      position: absolute;
      bottom: 14%;
      left: 0;
      right: 0;
      h3::before {
        content: "";
        display: inline-block;
        margin-right: 20px;
        width: 50px;
        height: 6px;
        background: #ec1c24;
        margin-bottom: 10px;
        @include respondToBelow(sm) {
          margin-bottom: 5px;
          margin-right: 10px;
          width: 40px;
          height: 5px;
        }
      }
      h3 {
        color: #fff;
        font-size: 42px;
        font-weight: 500;
        @include respondToBelow(sm) {
          font-size: 18px;
          padding: 0 18px;
        }
      }
    }
  }

  .row-1 {
    padding: 150px 0 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    @include respondToBelow(sm) {
      padding: 50px 20px 20px 20px;
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0 200px;
      @include respondToBelow(sm) {
        flex-direction: column-reverse;
        padding: 60px 0 40px;
      }
      .text {
        padding-left: 30px;
        flex-basis: 50%;
        flex-basis: calc(50% );
        @include respondToBelow(sm) {
          padding: 0;
        }
        h3 {
          font-size: 32px;
          line-height: 170%;

          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: start;
            padding: 40px 20px;
          }
        }
      }

      .anim-img {
        flex-basis: 50%;
        padding: 50px;
        img {
          width: 100%;
          flex-basis: 50%;
          @include respondToBelow(sm) {
            max-height: 250px;
          }
        }
      }
      .anim-img::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: 100%;
        background: #000;
        opacity: 0.1;
        border-radius: 100%;
        left: 80px;
        top: 0;
        z-index: 0;
        transition: all 0.3s ease-in;
        animation: linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
          width: 200px;
          height: 200px;
        }
      }
      @keyframes updown {
        0% {
          top: 0px;
        }
        50% {
          top: 50px;
        }
        100% {
          top: 0px;
        }
      }
    }
  }
  .row-2 {
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    @include respondToBelow(sm) {
      padding: 0 20px 20px 20px;
    }

    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0 200px;
      @include respondToBelow(sm) {
        flex-direction: column;
        padding: 60px 0;
      }
      .text {
        padding-right: 30px;
        flex-basis: 50%;
        
        flex-basis: calc(50% );
        @include respondToBelow(sm) {
          margin-left: 0;
          flex-basis: 100%;
        }
        h3 {
          font-size: 32px;
          line-height: 170%;

          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: start;
            padding: 40px 20px;
          }
        }
      }
      .anim-img {
        flex-basis: 50%;
        padding: 50px;
        img {
          width: 100%;
          flex-basis: 50%;
          @include respondToBelow(sm) {
            max-height: 250px;
          }
        }
      }
      .anim-img::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: 100%;
        background: #000;
        opacity: 0.1;
        border-radius: 100%;
        right: 80px;
        top: 0;
        z-index: 0;
        transition: all 0.3s ease;

        animation: linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
          width: 200px;
          height: 200px;
        }
      }
      @keyframes updown {
        0% {
          top: 0px;
        }
        50% {
          top: 50px;
        }
        100% {
          top: 0px;
        }
      }
    }
    .curve-line {
      width: 110vw;
      width: calc(100vw + 170px);
      position: absolute;
      // left: calc(-50vw + 50%);
      left: 0;
      top: -105px;
      z-index: 2;
      left: -70px;
      transition: all 0.3s ease;
      animation: linear infinite alternate;
      animation-name: ltor;
      animation-duration: 10s;
      @include respondToBelow(sm) {
        width: 185vw !important;
        top: -22px;
        left: -70px;
        transform: rotate(36deg) translateX(0%) translateY(80%);

        opacity: 0.5;
      }

      @keyframes ltor {
        0% {
          left: -70px;
        }
        25% {
          left: -140px;
        }
        50% {
          left: -170px;
        }
        75% {
          left: -140px;
        }
        100% {
          left: -70px;
        }
      }

      img {
        width: 100%;
        vertical-align: middle;
        //     @include respondToBelow(sm) {

        //  max-width:none;
        //  }
      }
    }
  }
  .row-3 {
    background: url(../../images/why-divo/madeindia.jpg) no-repeat center
      transparent;
    position: relative;
    padding: 180px;
    text-align: center;
    background-size: cover;
    @include respondToBelow(sm) {
      padding: 100px;
    }

    h3 {
      position: absolute;
      bottom: 150px;
      left: 0;
      right: 0;
      color: white;
      text-align: center;
      font-size: 42px;
      line-height: 170%;
      font-weight: 700;
      @include respondToBelow(sm) {
        font-size: 16px;
        padding: 0 45px;
        bottom: 80px;
      }
    }
  }
  .row-4 {
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    @include respondToBelow(sm) {
      padding: 50px 20px 20px 20px;
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 100px 0;
      @include respondToBelow(sm) {
        flex-direction: column-reverse;
        padding: 40px 0 40px;
      }

      .text {
        padding-left: 30px;
        flex-basis: 50%;
        flex-basis: calc(50% );
        @include respondToBelow(sm) {
          padding: 0;
          flex-basis: 100%;
        }
        h3 {
          font-size: 32px;
          line-height: 170%;
          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: start;
            padding: 20px 20px;
          }
        }
      }
      .anim-img {
        flex-basis: 50%;
        padding: 50px;
        img {
          width: 100%;
          flex-basis: 50%;
          @include respondToBelow(sm) {
            max-height: 250px;
          }
        }
      }
      .anim-img::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: 100%;
        background: #000;
        opacity: 0.1;
        border-radius: 100%;
        left: 80px;
        top: 0;
        z-index: 0;
        transition: all 0.3s ease;

        animation: linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
          width: 200px;
          height: 200px;
        }
      }
      @keyframes updown {
        0% {
          top: 0px;
        }
        50% {
          top: 50px;
        }
        100% {
          top: 0px;
        }
      }
    }
  }

  .row-5 {
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    @include respondToBelow(sm) {
      padding: 0 20px 20px 20px;
    }

    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 100px 0 100px;
      @include respondToBelow(sm) {
        flex-direction: column;
        padding: 40px 0;
      }
      .text {
        padding-right: 30px;
        flex-basis: 50%;
        
        flex-basis: calc(50% );
        @include respondToBelow(sm) {
          margin-left: 0;
          flex-basis: 100%;
        }
        h3 {
          font-size: 32px;
          line-height: 170%;

          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: start;
            padding: 40px 20px;
          }
        }
      }
      .anim-img {
        flex-basis: 50%;
        padding: 50px;
        img {
          width: 100%;
          flex-basis: 50%;
          @include respondToBelow(sm) {
            max-height: 250px;
          }
        }
      }
      .anim-img::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: 100%;
        background: #000;
        opacity: 0.1;
        border-radius: 100%;
        right: 80px;
        top: 0;
        z-index: 0;
        transition: all 0.3s ease;

        animation: linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
          width: 200px;
          height: 200px;
        }
      }
      @keyframes updown {
        0% {
          top: 0px;
        }
        50% {
          top: 50px;
        }
        100% {
          top: 0px;
        }
      }
    }
    .curve-line {
      // width:110vw;
      // width:calc(100vw + 170px);
      position: absolute;
      // left: calc(-50vw + 50%);
      left: 0;
      top: -10px;
      z-index: 2;
      left: -70px;
      right: -500px;
      transition: all 0.3s ease;
      animation: linear infinite alternate;
      animation-name: ltor;
      animation-duration: 10s;
      @include respondToBelow(sm) {
        width: 185vw !important;
        top: -22px;
        left: -70px;
        transform: rotate(36deg) translateX(0%) translateY(80%);

        opacity: 0.5;
      }

      @keyframes ltor {
        0% {
          left: -70px;
        }
        25% {
          left: -140px;
        }
        50% {
          left: -170px;
        }
        75% {
          left: -140px;
        }
        100% {
          left: -70px;
        }
      }

      img {
        width: 100%;
        vertical-align: middle;
        //     @include respondToBelow(sm) {
        //         top:-22px;
        //      width: 155vw !important;
        //      transform: rotate(
        //  36deg
        //  ) translateX(0%) translateY(80%);
        // //  opacity: 0.5;
        //  }
      }
    }
  }
  .row-6 {
    padding: 60px 0 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    @include respondToBelow(sm) {
      padding: 0px 20px 20px 20px;
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0 100px;
      @include respondToBelow(sm) {
        flex-direction: column-reverse;
        padding: 20px 0 40px;
      }
      .text {
        padding-right: 30px;
        flex-basis: 50%;
        flex-basis: calc(50% );
        @include respondToBelow(sm) {
          padding: 0;
        }
        h3 {
          font-size: 32px;
          line-height: 170%;

          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: start;
            padding: 40px 20px;
          }
        }
      }

      .anim-img {
        flex-basis: 50%;
        padding: 50px;
        img {
          width: 100%;
          flex-basis: 50%;
          @include respondToBelow(sm) {
            max-height: 250px;
          }
        }
      }
      .anim-img::before {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: 100%;
        background: #000;
        opacity: 0.1;
        border-radius: 100%;
        left: 80px;
        top: 0;
        z-index: 0;
        transition: all 0.3s ease-in;

        animation: linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
          width: 200px;
          height: 200px;
        }
      }
      @keyframes updown {
        0% {
          top: 0px;
        }
        50% {
          top: 50px;
        }
        100% {
          top: 0px;
        }
      }
    }
  }
  .row-7 {
    background: url(../../images/why-divo/whystrip2.jpg) no-repeat center
      transparent;
    position: relative;
    padding: 200px;
    text-align: center;
    background-size: cover;
    @include respondToBelow(sm) {
      padding: 150px;
    }

    h3 {
      position: absolute;
      bottom: 120px;
      left: 160px;
      right: 160px;
      color: white;
      text-align: center;
      font-size: 32px;
      line-height: 140%;

      @include respondToBelow(sm) {
        font-size: 16px;
        padding: 0 25px;
        bottom: 80px;
        left: 0;
        right: 0;
      }
    }
  }
  // .loader{
  // position: fixed;
  //     width: 100% !important;
  //     height: 100% !important;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     z-index: 99999999999;
  //     background: #18171c;
  //     left: 0;
  //     top: 0;
  // }

  // .loader_inner {
  //     position: relative;
  // }
  // .fill.full {
  //     width: 100%;
  // }
  // .fill{
  //     position: absolute;
  //     width: 0;
  //     overflow: hidden;
  //     transition: all 3s ease-in-out;

  // }
}
