.distribution{
    .banner{
        img{
            width: 100vw;
            height: 100vh;
            object-fit: cover;
        }
        .banner-text{
            position: absolute;
            bottom: 14%;
           left: 0;right:0;
            h3::before{
                content: "";
                display: inline-block;
                margin-right: 20px;
                width: 50px;
                height: 6px;
                background: #ec1c24;
                margin-bottom: 10px;
                @include respondToBelow(sm) {
                    margin-bottom:5px;
                    margin-right: 10px;
                    width: 40px;
                    height: 5px;
                  }  
            }
            h3{
                color: #fff;
                font-size: 42px;
                font-weight: 500;
                @include respondToBelow(sm) {
                    font-size:18px;
                    padding:0 18px;
                  }  
              
            }
        }   
    }
    .bg-img{
        // padding:180px 70px;
        background: url(../../images/bg.png);
        background-repeat: repeat;
        position: relative;
        z-index:2;
        width:100%;
        .row-1{
            padding:150px 0 0;
            background: url(../../images/bg.png);
            background-repeat: repeat;
            position: relative;
            z-index:2;
            @include respondToBelow(sm) {
                padding: 50px 20px 20px 20px;
                 
               } 
            .row-content{
              
                color:white;
                display:flex;   
                justify-content:space-between;
                align-items: center;
                padding:60px 0 200px;
                @include respondToBelow(sm) {
                   flex-direction: column;
                   padding:60px 0 40px;
                    
                  }  
                .text{
                    flex-basis: 50%;
                    margin-right: 40px;
                    flex-basis: calc(50% - 40px);
                    @include respondToBelow(sm) {
                        margin-right:0;
                    }
                    h3{
                        font-size:34px;
                        line-height:170%;
                       
                        @include respondToBelow(sm) {
                            font-size:16px;
                            text-align: center;
                            padding: 40px 20px;
                            
                          }  
                    }
                  
                }
                
                .anim-img{
                    img{
                    width:600px;
                    flex-basis: 50%;
                    @include respondToBelow(sm) {
                      max-height: 250px;
                      }  
                   
                }   
                }
                .anim-img::before{
                    content: "";
            position: absolute;
            width: 300px;
            height: 300px;
            max-width: 100%;
            max-height: 100%;
            background: #000;
            opacity: 0.1;
            border-radius: 100%;
            left: 80px;
            top: 0;
            z-index: 0;
            transition: all 0.3s ease-in;
        
        animation:linear infinite alternate;
        animation-name: updown;
        animation-duration: 6s;
        @include respondToBelow(sm) {
            width:200px;
            height:200px;
            } 
        
                }
                @keyframes updown {
                    0% {
                      top: 0px;
                    }
                    50% {
                      top: 50px;
                    }
                    100% {
                      top: 0px;
                    }
                  }
            }
        }
        .row-2{
        overflow: hidden;
        width: 100%;
        padding:50px 0 250px 0;
        background: url(../../images/bg.png);
        background-repeat: repeat;
        position: relative;
        z-index:2;
      width:100%;
      @include respondToBelow(sm) {
        padding:10px 0 80px 0;
    }
      .cu-line{
        // width:110vw;
        // width:calc(100vw + 170px);
                position: absolute;
                z-index: 2;
                // left: calc(-50vw + 50%);
              left:0;
                top: 78px;
                left:-70px;
                right:-500px;
                transition: all 0.3s ease;
                animation: linear infinite alternate;
                animation-name: ltor;
                animation-duration: 10s;
                @include respondToBelow(sm) {
                    top:300px;
                    
    width: 185vw !important;
    transform: rotate(
36deg
) translateX(0%) translateY(80%);
}
                
                @keyframes ltor {
                    0% {
                        left: -70px;
                    }
                    25% {
                        left: -140px;
                    }
                    50% {
                        left: -170px;
                    }
                    75% {
                        left: -140px;
                    }
                    100% {
                        left: -70px;
                    }
                }
            img{
                width:100%;
                vertical-align:middle;
              
            }
            }
        
        .row-content{
            color:white;
            display:flex;
            justify-content:space-between;
            align-items: center;
            // padding:60px 72px 200px;

           
            @include respondToBelow(sm) {
                flex-direction: column;
                padding:60px 20px;
            
                 } 
            .text{
               margin-left: 70px;
               @include respondToBelow(sm) {
                   margin-left:0;
               }
                h3{
                    font-size:30px;
                    line-height:170%;
                    text-align: left;
                    @include respondToBelow(sm) {
                        font-size:16px;
                        text-align:left;
                        padding-top:30px;
               
                        
                    }

                }
            }
        }
    }
    .double-rows{
      .rows{
        display: flex;
        flex-wrap: wrap;
      }
        .platform-1{
            display:flex;
            align-items: center;
          
            
            .text-img1{
                border: 1px solid #2C2B30;
        background: #18171C;
        margin-left: -1px;
        // margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        .icon{
           padding:30px 94px 30px 38px;
           
            }
            }
            .text-img{
                border: 1px solid #2C2B30;
        background: #18171C;
      
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        flex-basis: 33.333333%;
        .icon{
            padding:30px;
            @include respondToBelow(sm) {
                padding:15px;
            }
            img{
                width:100px;
                object-fit: contain;
                // height:40px;
            }
        }
            }
        }
       
    }
    
}
.rebanner{
    background: url(../../images/distribution/rebanner.jpg) no-repeat center transparent;
    // background-size: auto;
    background-size: cover;
    padding: 140px;
    text-align: center;
    color: #fff;
    @include respondToBelow(sm) {
        padding:80px;
        margin-bottom:20px;

    }
    .rebanner-content{
        h3{
            font-size: 32px;
            line-height:170%;
            font-weight: 500;
            @include respondToBelow(sm) {
                font-size:16px;
            }
        }
    }
        }
}