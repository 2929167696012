.button,
button {
  position: relative;
  line-height: 35px;
  height: 35px;
  border: 0;
  padding: 0 $gutter_width_sm;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  border-radius: 3px;
  font-size: 14px;
  white-space: nowrap;
  display: inline-block;
  background: $primary_color;
  transition: all 0.25s ease;
  box-shadow: $shadow !important;
  min-width: fit-content;
  &.button--plain {
    border: solid 1px $primary_color;
    background: transparent;
    line-height: 33px;
    color: $primary_color;
    box-shadow: none;
    &.button--small {
      line-height: 28px;
      height: 30px;
      font-size: 13px;
      padding: $gutter_width_xs;
      font-weight: normal;
    }
  }

  &.button--white {
    background: #fff;
    color: $primary_color;
    &.button--plain {
      border: solid 1px #fff;
      background: transparent;
      line-height: 33px;
      color: #fff;
      &.button--small {
        line-height: 28px;
        height: 30px;
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  &.button--rounded {
    border-radius: 40px;
  }
  &.button--transparent {
    border: none;
    background: transparent;
    line-height: 33px;
    color: $primary_color;
  }

  &.button_danger {
    background: $wb_red;
    &.button--plain {
      border: solid 1px $wb_red;
      background: transparent;
      line-height: 33px;
      color: $wb_red;
      &.button--small {
        line-height: 28px;
        height: 30px;

        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  &.button_withicon {
    display: inline-flex;

    align-items: center;
    justify-content: center;
    &.button_withicon--left {
      svg {
        margin-right: 10px;
      }
    }
    &.button_withicon--right {
      svg {
        margin-left: 10px;
      }
    }
  }
  &.button_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    padding: 0;

    &.button--small {
      width: 30px;
      height: 30px;
    }
    &.button--plain {
      border: 0;
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
  &.button--small {
    line-height: 30px;
    height: 32px;

    font-size: 13px;
    font-weight: normal;
  }

  &.button--plain.button--borderLess {
    border: 0;
  }
  &[disabled] {
    background: $light-bg;
    color: $muted_color;
  }
}

button.no-fill-button {
  color: #0069b1;
  background: #fff;
  border: 1px solid #0069b1;
}

.button-group {
  display: flex;
  > * {
    &:not(:only-child) {
      border-radius: 0;
    }
    ~ * {
      margin-left: 2px !important;
    }
    &:first-child:not(:only-child) {
      border-radius: 3px 0 0 3px;
    }
    &:last-child:not(:only-child) {
      border-radius: 0 3px 3px 0;
    }
  }
}
