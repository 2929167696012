$grey : #ccc;
.contact_page {
  background-color: #18171c;
  background: url(../../images/bg.png);
  background-repeat: repeat;
  position: relative;
  .contact_content {
    padding-top: 150px;
    background: url(../../images/contact/contactmap.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 70%;
    // padding-left: 30px;
    .contact_text {
      h3 {
        color: #fff;
        font-size: 36px;
        font-weight: 500;
        display: flex;
        align-items: center;
        @include respondToBelow(sm) {
          font-size:18px;

        }
        &::before {
          content: "";
          height: 6px;
          width: 50px;
          margin-right: 10px;
          background: #ec1c24;
          @include respondToBelow(sm) {
            margin-bottom:5px;
            margin-right: 10px;
           margin-left:18px;
            width: 40px;
            height: 5px;
          }
        }
      }
    }
    .contact_locs {
      color: $grey;
      margin-top: 30px;
      display: flex;
      @include respondToBelow(sm) {
        padding: 0 18px;
        
      }
      .loc_icon {
        padding-right: 35px;
        @include respondToBelow(sm) {
          padding-right: 5px;
         
        }
        .go-location{
          font-size:16px;
          line-height:12px;
          @include respondToBelow(sm) {
            font-size:12px;
            margin-bottom: 5px;
          }
        }
      }
     
      .locarea {
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        li:last-child{
          border-right: none;
        }
        li {
          padding: 0 35px;
          font-size: 20px;
          border-right: 2px solid $grey;
          line-height: 10px;
          height: 20px;
          display: flex;
          align-items: center;
       
          @include respondToBelow(sm) {
            font-size:10px;
            padding:0 5px;
            line-height:5px;
            border-right:1px solid $grey;
           
            
          }
        
        }
      }
    }
    .contact_section {
      padding: 150px 0 30px;
      max-width: 100%;
      overflow: hidden;
      @include respondToBelow(sm) {
        padding:100px 0 10px;
      }
      .form{
         min-height: 50vh;
         @include respondToBelow(sm) {
           min-height: 100vh;
         }
      }
      
      .slideup {
        margin-top: -80px;
        // transition: ease-in;
        @include respondToBelow(sm) {
          padding:20px;
        }
        a {
          padding: 8px;
          color: $grey;
          text-align: center;
          width: 50%;
          border: 1px solid $grey;
          font-size: 16px;
          transition: all 0.3s ease-in-out;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          @include respondToBelow(sm) {
            width:48%;
            font-size: 10px;
            
          }
        }
        :hover {
          background: #ec1c24;
          color: #fff;
          border-color: #ec1c24;
         
          
        }
        .active{
            background: #ec1c24;
            color: #fff;
            border-color: #ec1c24;
            // @include respondToBelow(sm) {
            //   width:48%;
            // }
        }
      }
      .choosebtn {
        display: flex;
        @include respondToBelow(sm) {
          padding: 0 18px;
        }
        a {
          padding: 8px;
          color: $grey;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          border: 1px solid $grey;
          font-size: 16px;
          transition: all 0.3s ease-in-out;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          @include respondToBelow(sm) {
            width:48%;
            font-size:10px;
          }
        }
        :hover {
          background: #ec1c24;
          color: #fff;
          border-color: #ec1c24;
         
        }
      }
    }
    .producer_form,
    .artist_form {
      margin-top: 50px;
      .form-group {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -100px;
        // padding-top: 50px;
        @include respondToBelow(sm) {
          margin:0 -4px;
        }
        .form-row {
          flex-basis: 33%;
          max-width: calc(100% / 3);
          padding: 30px 100px;
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
           -webkit-appearance: none;
            margin: 0;
            
            }
            input[type=number] {
              -moz-appearance: textfield;
            }
          @include respondToBelow(sm) {
            padding: 30px;
            flex-basis: 100%;
            max-width: 100%;
           
          }
          input,
          select {
            width: 100%;
            background: transparent;
            color: $grey;
            height: 50px;
            border: 0;
            border-bottom: 1px solid;
            padding: 8px 0;
            font-family: inherit;
            @include respondToBelow(sm) {
              font-size:14px;
            }
          }
          option{
            &:hover {
            background-color: yellow !important; 
          }}
          
        
        }
      }
      .form_submit {
        display: flex;
        justify-content: center;
        width: 100%;
        .send {
          width: 100%;
          background: transparent;
          color: $grey;
          border: 0;
          height: auto;
          border-bottom: 1px solid;
          padding: 5px 25px;
          border: 1px solid #ec1c24;
          transition: all 0.3s ease-in-out;
          margin-top: 20px;
          &:hover { //.send:hover
            background-color: #ec1c24;
          }
          &:disabled { // .send:disabled
            background: #ddd;
            color: #999;
            border:none;
            &:hover{
              background: #ddd;
              color: #999;
              border:none;

            }
          }
        }
       
      }
    }
  }
}

.customSelect{
 
  .customSelect__control{
    background: transparent;
    border: none;
    border-bottom: solid 1px #Fff;
    border-radius: 0;
    min-height: 0;
    box-shadow: none;
    &:hover{
      border-bottom: solid 1px #Fff;
      box-shadow: none;
    }
    .customSelect__value-container {
      padding: 0;
    }
    .customSelect__placeholder + div{
      padding: 0;
      margin:0 ;
    }
    .customSelect__indicator-separator{
      display: none;
    }
    .customSelect__single-value{
      color: #fff;
      
    }
  }
  .customSelect__menu{
    background: #18171c;
    color: #fff;
    box-shadow: none;
    border: solid 1px #fff;
    .customSelect__menu-list {
      padding: 0;
      .customSelect__option{
        padding: 1px 12px;
      }
      .customSelect__option--is-focused{
        color: #ec1c24;
        background: transparent;
        
      }
      .customSelect__option--is-selected{
        background: #ec1c24;
        color: #fff;
      }
    }
  }
}