.loader {
    position: fixed;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
    background: #18171c;
    left: 0;
    top: 0;
    .loader_inner {
        position: relative;
        .fill {
            position: absolute;
            width: 0;
            overflow: hidden;
            animation: giveWidth 0.75s ease-in-out infinite alternate;
            img{
                max-width: none;
            }
          }
          
      }

  }
  @keyframes giveWidth {
      0%{
          width: 0;
      }
      100%{
          width: 100%;
      }
  }