.partners {
  width: 100%;
  overflow-x: hidden;
  .banner {
    img {
      width: 100vw;
      height: 100vh;

      object-fit: cover;
    }
    .banner-text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 14%;

      h3::before {
        content: "";
        display: inline-block;
        margin-right: 20px;
        width: 50px;
        height: 6px;
        background: #ec1c24;
        margin-bottom: 10px;
        @include respondToBelow(sm) {
          margin-bottom: 5px;
          margin-right: 10px;
          width: 40px;
          height: 5px;
        }
      }
      h3 {
        color: #fff;
        font-size: 42px;
        font-weight: 500;
        @include respondToBelow(sm) {
          font-size: 18px;
          padding: 0 18px;
        }
      }
    }
  }

  .row-1 {
    padding: 180px 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 80px 0;
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respondToBelow(sm) {
        flex-direction: column;
        flex-basis: 100%;
        max-width: 100%;
      }
      // padding:60px 72px 200px;
      .text {
        width: 70%;
        h3 {
          font-size: 32px;
          line-height: 170%;
          padding-left: 70px;
          @include respondToBelow(sm) {
            padding-bottom: 40px;
            text-align: center;
            font-size: 18px;
            padding-left: 0;
          }
        }
      }
    }
  }

  .double-rows {
    @include respondToBelow(sm) {
      flex-basis: 100%;
      max-width: 100%;
    }
    .rows {
      display: flex;
      flex-wrap: wrap;
    }
    .platform-1 {
      display: flex;
      align-items: center;

      .text-img {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }

        .icon {
          padding: 30px;
          //    @include respondToBelow(sm) {
          //        padding: 10px;
          //    }
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
        }
      }
    }
  }

  .row-2 {
    padding: 50px 0 180px 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 10px 0 150px 0;
    }
    .cu-line {
      position: absolute;
      top: 105px;

      z-index: 1;
      animation: none;
      left: calc(-50vw + 50%);
      width: 110vw;

      img {
        width: 110vw;
        vertical-align: middle;
      }
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respondToBelow(sm) {
        flex-direction: column-reverse;
        flex-basis: 100%;
        max-width: 100%;
      }
      // padding:60px 72px 200px;
      .text {
        width: 120%;
        h3 {
          font-size: 32px;
          line-height: 170%;
          text-align: right;
          @include respondToBelow(sm) {
            padding-bottom: 40px;
            text-align: center;
            font-size: 18px;
            padding-left: 0;
          }
        }
      }
    }
  }

  .double-rows {
    @include respondToBelow(sm) {
      flex-basis: 100%;
      max-width: 100%;
      padding: 10px;
    }
    .rows {
      display: flex;
      flex-wrap: wrap;
    }
    .platform-1 {
      display: flex;
      align-items: center;
      .text-img1 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
          padding-left: 0;
          padding-right: 0;
        }
        .icon {
          padding: 30px 94px 30px 38px;

          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
      .text-img {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            overflow-wrap: break-word;
          }
        }
      }
      .text-img6 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
          display: none;
        }
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            overflow-wrap: break-word;
          }
        }
      }

      .text-img2 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        .icon {
          padding: 30px 48px 30px 45px;

          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
      .text-img3 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        .icon {
          padding: 30px 82px 30px 32px;

          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
      .text-img4 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        .icon {
          padding: 30px 71px 30px 32px;

          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
      .text-img5 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        .icon {
          padding: 30px 110px 30px 38px;

          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }
    }
  }

  .row-3 {
    padding: 50px 0 180px 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 10px 0 100px 0;
    }
    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respondToBelow(sm) {
        flex-direction: column;
      }

      // padding:60px 72px 200px;
      .text {
        width: 70%;
        h3 {
          font-size: 32px;
          line-height: 170%;
          padding-left: 70px;
          @include respondToBelow(sm) {
            font-size: 18px;
            padding-left: 0;
            text-align: center;
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  .double-rows {
    .rows {
      display: flex;
      flex-wrap: wrap;
    }
    .platform-1 {
      display: flex;
      align-items: center;

      .text-img {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
        }
      }
    }
  }
  .row-4 {
    background: url(../../images/partners/clientsbg.jpg) no-repeat center
      transparent;
    position: relative;
    padding: 150px;
    text-align: center;
    background-size: cover;
    @include respondToBelow(sm) {
      padding: 120px;
    }

    h3 {
      position: absolute;
      top: 100px;
      left: 500px;
      right: 500px;
      color: white;
      text-align: center;
      font-size: 32px;
      line-height: 170%;
      font-weight: 500;
      @include respondToBelow(sm) {
        font-size: 16px;
        padding: 0 45px;
        right: 0;
        left: 0;
        text-align: center;
        top: 80px;
      }
    }
  }

  .row-5 {
    padding: 250px 0 180px 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 100px 0 100px 0;
    }
    .cu-line {
      position: absolute;
      width: 110vw;
      top: 305px;
      z-index: 1;
      animation: none;
      left: calc(-50vw + 50%);

      img {
        width: 110vw;
        vertical-align: middle;
      }
    }

    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respondToBelow(sm) {
        flex-direction: column;
        flex-basis: 100%;
        max-width: 100%;
      }

      // padding:60px 72px 200px;
      .text {
        width: 30%;
        h3 {
          font-size: 32px;
          line-height: 170%;
          padding-left: 70px;
          @include respondToBelow(sm) {
            font-size: 18px;
            padding-left: 0;
            text-align: center;
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  .double-rows {
    @include respondToBelow(sm) {
      flex-basis: 100%;
      max-width: 100%;
    }
    .rows {
      display: flex;
      flex-wrap: wrap;
    }
    .platform-1 {
      display: flex;
      align-items: center;

      .text-img-1 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 100%;
          max-width: 100%;
          padding-left: 80px;
          padding-right: 80px;

          //    display:none;
        }
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
        }
      }
    }
  }

  .row-6 {
    padding: 50px 0 180px 0;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 30px 0 100px 0;
    }

    .row-content {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include respondToBelow(sm) {
        flex-direction: column-reverse;
      }

      // padding:60px 72px 200px;
      .text {
        width: 120%;
        h3 {
          font-size: 32px;
          line-height: 170%;
          text-align: right;
          @include respondToBelow(sm) {
            font-size: 18px;
            text-align: center;
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  .double-rows {
    .rows {
      display: flex;
      flex-wrap: wrap;
    }
    .platform-1 {
      display: flex;
      align-items: center;

      .text-img {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
          h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 0;
            margin-top: 0;
            text-align: left;
            font-weight: 500;
            overflow-wrap: break-word;
          }
        }
      }
      .text-img-11 {
        border: 1px solid #2c2b30;
        background: #18171c;
        margin-left: -1px;
        margin-bottom: -1px;
        position: relative;
        z-index: 10;
        padding-right: 15px;
        padding-left: 15px;
        @include respondToBelow(sm) {
          flex-basis: 100%;
          max-width: 100%;
          padding-left: 80px;
          padding-right: 80px;

          display: none;
        }
        .icon {
          padding: 30px;
          img {
            width: 140px;
            object-fit: contain;
            height: 40px;
          }
        }
      }
    }
  }

  .row-7 {
    padding: 50px 0  ;
    background: url(../../images/bg.png);
    background-repeat: repeat;
    position: relative;
    z-index: 2;
    width: 100%;
    @include respondToBelow(sm) {
      padding: 20px 0 50px 0;
    }
    .cu-line {
      position: absolute;
      width: 110vw;
      top: 230px;
      z-index: 1;
      animation: none;
      left: calc(-50vw + 50%);

      img {
        width: 110vw;
        vertical-align: middle;
      }
    }
    .artists {
      .art-text {
        h3 {
          font-size: 28px;
          color: #fff;
          margin: 0 0 90px;
          @include respondToBelow(sm) {
            font-size: 16px;
            text-align: center;
          }
        }
      }
      .artists-group {
        position: relative;
        z-index: 999;
        .artists {
          display: flex;
          align-items: flex-start;
          flex-wrap: nowrap;
          border-radius: 0;
          @include respondToBelow(sm) {
            flex-wrap: wrap;
            max-width: 80%;
            margin: auto;
          }
        }
        .each-art {
          width: 20%;
          padding: 10px;
          pointer-events: none;
          position: relative;
          padding-bottom: 50px;
          text-align: center;
          @include respondToBelow(sm) {
            width: 50%;
          }

          img {
            border: 1px solid #4d4c51;
            padding: 10px;
            width: 60%;
            border-radius: 50%;
            background: #18171c;
            @include respondToBelow(sm) {
                width: 80%;
              }
          }
          .text {
            padding-left: 18px;
            @include respondToBelow(sm) {
              padding-left: 5px;
            }

            h5 {
              color: #cccccc;
              font-size: 13px;
              text-align: center;
              margin-top: 15px;
              margin-bottom: 0;
              padding: 5px;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              @include respondToBelow(sm) {
                font-size: 12px;
              }
            }
          }
          &:nth-child(1) {
            margin-top: 75px;
          }

          &:nth-child(2) {
            margin-top: 125px;
          }
          &:nth-child(3) {
            margin-top: 100px;
          }
          &:nth-child(5) {
            margin-top: 50px;
          }
          @include respondToBelow(sm) {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
