.footer{
    display: flex;
    justify-content: space-between;
    padding: 150px 0 70px 0;
    flex-wrap: wrap;
    align-items: flex-start;
   
    @include respondToBelow(sm) {
        flex-direction:column;
        align-items:center;
        text-align: center;
        padding:45px 0 50px 0;
    }
    .footer-logo{
        flex-basis: 15%;
      @include respondToBelow(sm) {
        margin-bottom: 30px;
        flex-basis: 100%;
        max-width: 100%;
      }
        img{
            width:148px;
           
           
        }
    }
    .footer-content{
        flex-basis: calc(35% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        @include respondToBelow(sm) {
            flex-basis: calc(35% - 120px);
            margin-left: 60px;
            margin-right: 60px;
        }
    
        .footer-text{
       
            p{
                font-size:14px;
                margin-bottom: 15px;
                line-height:20px;
                color:#989898;
                @include respondToBelow(sm) {
                    margin-bottom: 20px;
                    font-size:12px;
                }
                
            }
            .youtube{
                font-size: 30px;
                margin-right:18px;
                color:#989898;
            }
        }
    }
    .footer-nav{
        display: flex;
    white-space: nowrap;
    flex-basis: 50%;
    @include respondToBelow(sm) {
        margin-top:40px;
    
       
    }
     
        .ul-list{
            display:flex;
            flex-wrap: wrap;
            @include respondToBelow(sm) {
                flex-direction: column;
                align-items:center;
               
            }
            li{
                list-style: none;
          
              }
              
              
              .link-tag{
                padding: 0 6px;
                font-size:14px;
                color:#989898;
                @include respondToBelow(sm) {
                    font-size:12px;
                }
              
              }
              
            
        }
    }
}