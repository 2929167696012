.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $gutter_width 0;
  p {
    margin: 0;
    letter-spacing: 0;
  }
  .heading-ttl {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    letter-spacing: 0;
  }
  &.no-mar-top {
    margin-top: 0;
  }
  &.no-mar-bottom {
    margin-bottom: 0;
  }
  &.no-mar {
    margin: 0;
  }
  .action {
    color: $primary_color;
    font-size: 14px;
  }
}
