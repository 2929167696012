$text_primary_color: #1d2326;
$primary_color: #e68542;
$light_bc: #fffaf7;
$secondary_color: #9c1846;
$black_bg: #001835;
$border_color: #c4c4c4;
$button_color: $primary_color;
$page_bg_color: #f4f4f5;
$link_color: #000000;
$header_color: $primary_color;
$muted_color: #898c8e;
$faded_color: #c0c0c0;
$rc_main: #d32f2f;
$light-bg: #f7f7f7;
$body_gutter: 75px;
//english font
$font-sans: "Comfortaa", cursive;
$font-serif: "Domine", serif;

$wb-grad: -webkit-linear-gradient(135deg, $primary_color, $secondary_color);
$semi-grad: linear-gradient(
  to bottom,
  rgba($primary_color, 0.75) 15%,
  rgba($primary_color, 0.5) 50%,
  rgba($secondary_color, 0.5) 100%
);
$inset: inset 0 0 15px 0 rgba(0, 0, 0, 0.1);
$shadowDark: 0 0 10px rgba(0, 0, 0, 0.2), 0 6px 10px -4px rgba(0, 0, 0, 0.2);
$shadow: 0px 2px 5px rgba(43, 43, 43, 0.05);
$shadow_inv: 0px -2px 5px rgba(43, 43, 43, 0.05);
$cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$wb-green: #008a69;
$wb-red: #f44336;
$wb-blue: #2196f3;
$wb-orange: #ff9834;

$gutter_width: 24px;
$gutter_width_lg: $gutter_width * 1.5;
$gutter_width_more: $gutter_width * 3;
$gutter_width_sm: $gutter_width / 1.5;
$gutter_width_xs: $gutter_width / 3;

// Extra small screen / phone
$screen-xs-min: 400px;

// Small screen / tablet
$screen-sm-min: 768px;

// Medium screen / desktop
$screen-md-min: 992px;

// Large screen / wide desktop
$screen-lg-min: 1200px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

$max-width: 1200px;

$home-space: 40px;
$pdp_space: 30px;
$pdp_detail_gap: 20px;
$pdp_detail_gap_mini: 12px;
$form_space: 15px;
$pdp_detail_gap_new: 10px;
