.banner-divo{
  width: 100%;
  overflow-x: hidden;
}
.banner {
    background-position: top 0 right;
    background-size: auto 85vh;
    background-size: cover;
 
  }
  .banner_img img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .banner_text h3 {
    color: #fff;
    position: absolute;
    bottom: 8%;
    line-height: 150%;
    font-size:42px;
    font-weight: 500;
    width:65%;
   
    @include respondToBelow(sm) {
      font-size:18px;
      padding:0 18px;
      width:100%;
    } 
  }
  .banner_text h3::before {
    content: "";
    display: block;
    width: 70px;
    height: 6px;
    background: #ec1c24;
    margin-bottom: 15px;
   
   
   @include respondToBelow(sm) {
    margin-bottom:5px;
    margin-right: 10px;
    width: 70px;
    height: 5px;
  } 
  }
  
