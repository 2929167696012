.nav{
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding:40px 0;
  position:absolute;
  left:0;
  right:0; 
  @include respondToBelow(sm) {
  padding-left:18px;
  padding-right: 18px;
   
  }
.nav-logo{
  img{
  width:148px;
  @include respondToBelow(sm) {
    width:100px;
  }
 
}
}
.bars{
  color:white;
}
.navbar-toggle{
@include respondToBelow(sm) {
display:flex;
justify-content:flex-end;
  
}  
}
.navbar-links {
  @include respondToBelow(sm){
  display: none;
  width: 100%;
  text-align: center;
}
}
.active {
  @include respondToBelow(sm){
  display: block;
  }
}

.ul-list{
  
  li{
    list-style: none;
    display: inline-block;
    font-weight: 700;
  }
  
  .link-tag{
    padding: 0 15px;
    color: #fff;
    text-shadow: 1px 0 2px rgba(0,0,0,0.95);
    font-weight: 700;
   
  }
}
@include respondToBelow(sm){
  .logo {
    z-index: 999999;
    position: relative;
  }
  .ul-list {
    margin-top: 0;
  }
  .menu_toggle span {
    width: 100%;
    height: 2px;
    background: #fff;
    display: block;
    margin: 8px 0;
    transition: all 0.3s ease-in-out;
    position: relative;
    right: 0;
    top: 0;
    opacity: 1;
  }
  .menu_toggle {
    display: inline-block;
    width: 40px;
  }

  .menu_toggle.active span:first-child {
    top: 10px;
    transform: rotate(-45deg);
  }

  .menu_toggle.active span:last-child {
    top: -10px;
    transform: rotate(45deg);
  }
  .menu_toggle.active span:nth-child(2) {
    // right: -40px;
    opacity: 0;
  }
  .ul-list {
    top: -100%;
    transition: all 0.3s ease-in-out;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.95);
    z-index: 99999;
    flex-direction: column;
    opacity: 0;
  }

  .ul-list.active {
    opacity: 1;
    top: 0;
  }

  .menu_toggle {
    display: inline-block;
    width: 40px;
    z-index: 999999;
    position: relative;
    top: 12px;
    // float: right;
  }
  .ul-list li a {
    line-height: 50px;
  }
}
}
